export const useGeoStore = defineStore('geo', () => {
    const zip = ref('');

    return {
        zip,
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useGeoStore, import.meta.hot));
}
