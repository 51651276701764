<script setup lang="ts">
const props = withDefaults(
    defineProps<{
        type?: 'primary' | 'secondary' | 'tertiary';
        shadowed?: boolean;
        shiny?: boolean;
    }>(),
    {
        type: 'primary',
        shadowed: false,
        shiny: true,
    }
);

const appConfig = useAppConfig();
</script>

<template>
    <div
        class="base-button"
        :class="{
            primary: props.type === 'primary',
            secondary: props.type === 'secondary',
            tertiary: props.type === 'tertiary',
            shadowed: props.shadowed,
            shiny: props.shiny,
            'theme--default':
                appConfig.theme === 'default' ||
                appConfig.theme === 'audi' ||
                appConfig.theme === 'hub' ||
                appConfig.theme === 'cahf',
            'theme--bmw': appConfig.theme === 'bmw',
        }"
    >
        <div class="slot-wrap">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="postcss" scoped>
/* These variables are re-defined because primary and secondary colors can vary from dealer to dealer */
* {
    --base-button-primary-bg: var(--color-button-primary-bg);
    --base-button-primary-text: var(--color-button-primary-text);
    --base-button-primary-hover-bg: var(--color-button-primary-hover-bg);
    --base-button-primary-hover-bg-active: var(
        --color-button-primary-hover-bg-active
    );
    --base-button-secondary-bg: var(--color-font-light);
    --base-button-secondary-text: var(--color-font-dark);
    --base-button-tertiary-bg: transparent;
    --base-button-tertiary-text: var(--color-font-dark);
    --base-button-hover-text: var(--color-font-light);
    --base-button-disabled-text: #999;
    --base-button-disabled-bg: #666;
}

.base-button {
    height: 56px;

    & .slot-wrap {
        height: 100%;
        width: 100%;
        user-select: none;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: var(--transition-fast);

        & > :deep(*:first-child) {
            font-family: var(--font-family);
            font-size: var(--base-button-font-size, 1rem);
            cursor: pointer;
            width: 100%;
            height: 100%;
            border: none;
            background: none;
            color: inherit;
            transition: inherit;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: var(--base-button-padding, 1rem 2rem);

            & :not(:first-child) {
                margin-left: var(--span-wrap, 0.8rem);
            }
        }
    }

    &.theme--default {
        border-radius: 40px;

        & .slot-wrap {
            border-radius: 40px;
        }
    }

    &.theme--bmw {
        --base-button-font-size: 18px;

        border-radius: 0.25rem;

        & .slot-wrap {
            border-radius: 0.25rem;

            & > :deep(*:first-child) {
                font-weight: var(--font-weight-bold);
            }
        }
    }

    &.primary {
        & .slot-wrap {
            background-color: var(--base-button-primary-bg);
            color: var(--base-button-primary-text);
        }
    }

    &.secondary {
        & .slot-wrap {
            background-color: var(--base-button-secondary-bg);
            color: var(--base-button-secondary-text);
        }
    }

    &.tertiary {
        & .slot-wrap {
            border: 2px solid
                var(--base-button-tertiary-border-color, transparent);
            background-color: var(--base-button-tertiary-bg);
            color: var(--base-button-tertiary-text);
        }
    }

    &:focus-within,
    &:focus,
    &:hover {
        &.primary,
        &.secondary {
            & .slot-wrap {
                background: var(--base-button-primary-hover-bg);
                color: var(--base-button-hover-text);
            }
        }

        &.tertiary {
            & .slot-wrap {
                border-color: var(--color-accent);
                color: var(--color-accent);
            }
        }
    }

    &:active {
        &.primary,
        &.secondary {
            & .slot-wrap {
                background: var(--base-button-primary-hover-bg-active);
            }
        }
    }

    &.disabled {
        &.primary,
        &.secondary {
            & .slot-wrap {
                background-color: var(--base-button-disabled-bg);
                color: var(--base-button-disabled-text);
                cursor: default;
                pointer-events: none;
            }
        }
    }

    &.shiny {
        &.primary,
        &.secondary {
            & .slot-wrap {
                position: relative;
                overflow: hidden;

                &::before {
                    pointer-events: none;
                    content: '';
                    background-color: rgba(255 255 255 / 50%);
                    height: 100%;
                    width: 4rem;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: -6rem;
                    transform: skewX(-45deg) translateX(0);
                    transition: none;
                }

                &:focus-within,
                &:focus,
                &:hover {
                    &::before {
                        transition: all 0.5s ease-in-out;
                        transform: skewX(-45deg) translateX(950%);
                    }
                }
            }
        }
    }

    &.shadowed {
        &.primary,
        &.secondary {
            /* stylelint-disable-next-line no-descending-specificity */
            & .slot-wrap {
                --bottom-border-size: 4px; /* how much border-bottom becomes visible on hover */
                --button-rise-distance: -2px; /* how much the button rises on hover */
                --box-shadow-default: 0 0.2px 0.4px rgba(0 0 0 / 4.7%),
                    0 0.6px 1px rgba(0 0 0 / 6.7%),
                    0 1.2px 1.9px rgba(0 0 0 / 8.3%),
                    0 2.6px 4px rgba(0 0 0 / 10.3%),
                    0 7px 11px rgba(0 0 0 / 15%);
                --box-shadow-hover: 0 0.2px 0.5px rgba(0 0 0 / 9%),
                    0 0.6px 1.2px rgba(0 0 0 / 10%),
                    0 1.2px 2.5px rgba(0 0 0 / 13%),
                    0 2.6px 5.1px rgba(0 0 0 / 17%),
                    0 7px 14px rgba(0 0 0 / 25%);
                --box-shadow-active: 0 0.1px 0.2px rgba(0 0 0 / 16.7%),
                    0 0.3px 0.4px rgba(0 0 0 / 24%),
                    0 0.7px 0.9px rgba(0 0 0 / 30%),
                    0 1.5px 1.8px rgba(0 0 0 / 37.3%),
                    0 4px 5px rgba(0 0 0 / 54%);

                box-shadow: var(--box-shadow-default);

                &:focus-within,
                &:focus,
                &:hover {
                    box-shadow: var(--box-shadow-hover);
                    border-bottom: var(--bottom-border-size) solid
                        rgba(0 0 0 / 30%);
                    transform: translateY(var(--button-rise-distance));
                }
            }
        }

        &:active {
            &.primary,
            &.secondary {
                & .slot-wrap {
                    box-shadow: var(--box-shadow-active);
                    border-bottom: none;
                    transform: translateY(0);

                    /* fixes button border color flashing a different color on click. */
                    border-color: rgba(0 0 0 / 30%);
                }
            }
        }
    }
}
</style>
