import revive_payload_client_Hjmf4WeR2N from "/home/runner/work/sites.retail/sites.retail/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ySWanUoDla from "/home/runner/work/sites.retail/sites.retail/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Or2oaplyd8 from "/home/runner/work/sites.retail/sites.retail/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_IPyPwrjcYy from "/home/runner/work/sites.retail/sites.retail/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_rpN6vpLoWw from "/home/runner/work/sites.retail/sites.retail/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_I8AhPB5cLY from "/home/runner/work/sites.retail/sites.retail/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_rZ2LG0wREi from "/home/runner/work/sites.retail/sites.retail/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/sites.retail/sites.retail/sites/dealers/cyd/.nuxt/components.plugin.mjs";
import prefetch_client_GGXNTOA1qA from "/home/runner/work/sites.retail/sites.retail/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_f1SKBejfl8 from "/home/runner/work/sites.retail/sites.retail/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_Wpj0cDp0U1 from "/home/runner/work/sites.retail/sites.retail/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auth_client_LOr4Vem3E1 from "/home/runner/work/sites.retail/sites.retail/layers/base/plugins/auth.client.ts";
import cagGoogle_client_65c6PNkYlA from "/home/runner/work/sites.retail/sites.retail/layers/base/plugins/cagGoogle.client.ts";
import sentry_client_sVMmoh2Eyf from "/home/runner/work/sites.retail/sites.retail/layers/base/plugins/sentry/sentry.client.ts";
export default [
  revive_payload_client_Hjmf4WeR2N,
  unhead_ySWanUoDla,
  router_Or2oaplyd8,
  _0_siteConfig_IPyPwrjcYy,
  payload_client_rpN6vpLoWw,
  check_outdated_build_client_I8AhPB5cLY,
  plugin_vue3_rZ2LG0wREi,
  components_plugin_KR1HBZs4kY,
  prefetch_client_GGXNTOA1qA,
  i18n_f1SKBejfl8,
  chunk_reload_client_Wpj0cDp0U1,
  auth_client_LOr4Vem3E1,
  cagGoogle_client_65c6PNkYlA,
  sentry_client_sVMmoh2Eyf
]