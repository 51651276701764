<script setup lang="ts">
import ChevronRight from '@base/assets/icons/chevron_right.svg?raw';

const props = defineProps<{
    error: {
        url: string;
        statusCode: number;
        statusMessage: string;
        message: string;
        stack: string;
    };
}>();

const route = useRoute();
const appConfig = useAppConfig();
const dealersStore = useDealersStore();

const newPath = computed(() => {
    if (appConfig.hub.isChoice) {
        return '/search/new/cars';
    } else if (appConfig.hub.location) {
        return `/search/new/cars/${appConfig.hub.location}`;
    } else {
        return '/search/new';
    }
});

const usedPath = computed(() => {
    if (appConfig.hub.isValue) {
        return '/search/used/cars';
    } else if (appConfig.hub.isChoice) {
        return '/search/used/cars';
    } else if (appConfig.hub.location) {
        return `/search/used/cars/${appConfig.hub.location}`;
    } else {
        return '/search/used';
    }
});

useSeoMeta({
    robots: 'noindex,nofollow',
    title: `Oops! There was a ${props.error.statusCode} error | ${dealersStore.siteName}`,
    description: `Oops! There was a ${props.error.statusCode} error | ${dealersStore.siteName}`,
});
useHead({
    link: () => [
        {
            rel: 'canonical',
            href: `https://${dealersStore.siteUrl}${route.path}`,
        },
    ],
});
</script>

<template>
    <NuxtLayout>
        <div class="error-page">
            <CenterContent padding="50px 2.5rem">
                <h1>{{ props.error.statusCode }}</h1>

                <h2>Oops!</h2>

                <h3 v-if="props.error.statusCode === 404">
                    We cannot seem to find this page!
                </h3>

                <p v-else>{{ props.error.statusMessage }}</p>

                <BaseButton class="redirect">
                    <a href="/" @click.prevent="clearError({ redirect: '/' })">
                        <span><b>Home</b></span>

                        <BaseIcon
                            :icon-html="ChevronRight"
                            aria-hidden
                            class="chevron-icon"
                        />
                    </a>
                </BaseButton>

                <BaseButton v-if="!appConfig.isUsedSite" class="redirect">
                    <a
                        :href="newPath"
                        @click.prevent="clearError({ redirect: newPath })"
                    >
                        <span><b>Search</b> New</span>

                        <BaseIcon
                            :icon-html="ChevronRight"
                            aria-hidden
                            class="chevron-icon"
                        />
                    </a>
                </BaseButton>

                <BaseButton class="redirect">
                    <a
                        :href="usedPath"
                        @click.prevent="clearError({ redirect: usedPath })"
                    >
                        <span><b>Search</b> Pre-Owned</span>

                        <BaseIcon
                            :icon-html="ChevronRight"
                            aria-hidden
                            class="chevron-icon"
                        />
                    </a>
                </BaseButton>
            </CenterContent>
        </div>
    </NuxtLayout>
</template>

<style lang="postcss" scoped>
.error-page {
    text-align: center;
}

h1 {
    font-size: 72px;
    color: var(--color-error);
}

h2 {
    font-size: 48px;
    text-transform: inherit;
}

h3,
p {
    padding: 30px 0;
}

.redirect {
    margin: 10px;
    display: inline-block;
}

.chevron-icon {
    --base-icon-size: 26px;
    --base-icon-stroke-width: 3;
}
</style>
