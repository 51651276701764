export default defineNuxtPlugin(() => {
    const pushToDataLayer = (params: Record<string, string | undefined>) => {
        const debug = process.env.NODE_ENV !== 'production';

        const dataToPush = {
            event: 'trackEvent',
            action: 'click',
            category: 'lead',
            ...params,
        };

        if (debug) {
            // eslint-disable-next-line no-console
            console.log(dataToPush);
        } else {
            window.dataLayer.push(dataToPush);
        }
    };

    const pushPhoneToDataLayer = (
        phoneNumber: string,
        componentName: string
    ) => {
        const route = useRoute();

        pushToDataLayer({
            category: 'phone',
            url: route.path,
            component: componentName,
            phoneNumber,
        });
    };

    const pushPageViewToDataLayer = (url: string, name: string) => {
        const debug = process.env.NODE_ENV !== 'production';

        const dataToPush = {
            event: 'nuxtRoute',
            pageType: 'PageView',
            pageUrl: url,
            routeName: name,
        };

        if (debug) {
            // eslint-disable-next-line no-console
            console.log(dataToPush);
        } else {
            window.dataLayer.push(dataToPush);
        }
    };

    const pushToHondaDataLayer = (
        params: Record<string, string | number | undefined>
    ) => {
        const debug = process.env.NODE_ENV !== 'production';

        const dataToPush = {
            ...params,
        };

        if (debug) {
            // eslint-disable-next-line no-console
            console.log(dataToPush);
        } else {
            window.dataLayer.push(dataToPush);
        }
    };

    return {
        provide: {
            cagGoogle: {
                pushToDataLayer,
                pushPhoneToDataLayer,
                pushPageViewToDataLayer,
                pushToHondaDataLayer,
            },
        },
    };
});
