<script setup lang="ts">
export interface Props {
    padding?: string;
}

const props = withDefaults(defineProps<Props>(), {
    padding: '0',
});
</script>

<template>
    <div :style="{ padding: props.padding }" class="center">
        <slot></slot>
    </div>
</template>

<style lang="postcss" scoped>
.center {
    max-width: 1200px; /* Use max-width instead of width to account for vertical scrollbar */
    margin: 0 auto;
}
</style>
