export interface ValueDealerWithGoogle extends DealersValueDetail {
    distance: number | null;
    geo?: google.maps.LatLng;
}

export const useDealersStore = defineStore('dealers', () => {
    const geoStore = useGeoStore();
    const appConfig = useAppConfig();
    const dealersApi = new DealersApi();

    const all = shallowRef<Readonly<DealersDetail>[]>([]);
    const valueDealers = ref<ValueDealerWithGoogle[]>([]);
    const currentDealer = ref<DealersDetail>({
        arkona: '',
        arkonaNew: '',
        dealership: '',
        url: '',
        makes: [],
        phone: '',
        servicePhone: '',
        partsPhone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        leadEmail: '',
        salesMF: '',
        salesSAT: '',
        salesSUN: '',
        serviceMF: '',
        serviceSAT: '',
        serviceSUN: '',
        partsMF: '',
        partsSAT: '',
        partsSUN: '',
        facebook: '',
        blog: '',
        youtube: '',
        instagram: '',
        hasFinanceBonus: false,
        hasLease: false,
        xtimeKey: '',
        awayMessages: {
            quickService: '',
            chat: '',
        },
        service: {
            expressShopId: '',
            mainShopId: '',
        },
    });
    const currentDealerDisclaimer = ref('');

    const dealerByArkona = (arkona: string) => {
        return all.value.find(
            (dealer) => dealer.arkona.toLowerCase() === arkona.toLowerCase()
        );
    };

    const dealersByArkonas = (arkonas: string[]) => {
        return all.value.filter((dealer) => arkonas.includes(dealer.arkona));
    };

    const dealersByMake = (make: string) => {
        return all.value.filter((dealer) => dealer.makes.includes(make));
    };

    const valueDealerByArkona = (arkona: string) => {
        return valueDealers.value.find((dealer) => dealer.arkona === arkona);
    };

    const valueDealerByName = (name: string) => {
        return valueDealers.value.find((dealer) => dealer.name === name);
    };

    /**
     * Create this map so distance filter in SRP doesn't need to find dealer
     * for every vehicle.
     */
    const valueDealerDistanceMap = computed(() => {
        const map = new Map<string, number>();

        for (const dealer of valueDealers.value) {
            const distance = dealer.distance;
            map.set(dealer.arkona, distance ?? 0);
        }

        return map;
    });

    const allMakes = computed(() => {
        const makes = new Set<string>();

        for (const dealer of all.value) {
            for (const make of dealer.makes) {
                makes.add(make);
            }
        }

        return Array.from(makes).sort();
    });

    // These getters help handle the differences between hub and dealer sites
    const siteName = computed(() => {
        return appConfig.seoOptions.name || currentDealer.value.dealership;
    });

    const siteUrl = computed(() => {
        return appConfig.seoOptions.url || currentDealer.value.url;
    });

    /** The time zone based on the dealer's state. */
    const dealerTimeZone = computed(() => {
        return currentDealer.value.state === 'NV'
            ? 'America/Los_Angeles'
            : 'America/Phoenix';
    });

    const GET_DEALER = async (arkona: string) => {
        try {
            const [dealership, disclaimer] = await Promise.all([
                dealersApi.getDealerByArkona(arkona),
                dealersApi.getDealerDisclaimer(arkona),
            ]);

            currentDealer.value = dealership;
            currentDealerDisclaimer.value = disclaimer;
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
        }
    };

    const GET_ALL_DEALERS = async () => {
        try {
            all.value = await dealersApi.getAllDealers();
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
        }
    };

    const GET_DEALERS_BY_CITY = async () => {
        try {
            all.value = await dealersApi.getDealerByCity(
                appConfig.api.dealers.city
            );
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
        }
    };

    const GET_DEALERS_BY_STATE = async () => {
        try {
            all.value = await dealersApi.getDealerByState(
                appConfig.api.dealers.stateCode
            );
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
        }
    };

    const GET_DEALERS_BY_PHOTOLOCATION = async () => {
        try {
            all.value = await dealersApi.getDealerByPhotoLocation(
                appConfig.api.dealers.photoLocation
            );
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
        }
    };

    const GET_DEALERS_BY_MAKE = async () => {
        try {
            const dealers = await dealersApi.getAllDealers();

            all.value = dealers.filter((dealer) =>
                dealer.makes.includes(appConfig.api.dealers.make)
            );
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
        }
    };

    const GET_VALUE_DEALERS = async () => {
        try {
            const response = await dealersApi.getValueDealers();

            valueDealers.value = response.map((dealer) => {
                return { ...dealer, distance: null };
            });
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
        }
    };

    const GET_MAP_INFO = async () => {
        try {
            const geocoder = new google.maps.Geocoder();
            const promises: Promise<google.maps.GeocoderResponse>[] = [];

            valueDealers.value.forEach((dealer) => {
                const address = `${dealer.address.street} ${dealer.address.city}, ${dealer.address.state} ${dealer.address.zip}`;
                promises.push(geocoder.geocode({ address }));
            });

            const geoCodes = await Promise.all(promises);
            geoCodes.forEach((geo, i) => {
                valueDealers.value[i].geo = geo.results[0].geometry.location;
            });
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
        }
    };

    const UPDATE_DISTANCES = (
        distances: google.maps.DistanceMatrixResponseElement[]
    ) => {
        valueDealers.value.forEach((dealer, index) => {
            const result = distances[index];

            if (result.status === 'OK') {
                dealer.distance = parseInt(
                    result.distance.text.split(' ')[0].replace(',', ''),
                    10
                );
            } else {
                dealer.distance = null;
            }
        });
    };

    const GET_DISTANCES_FROM_ZIP = () => {
        // only do anything if zip code is correct format
        if (!geoStore.zip || geoStore.zip.length !== 5) return;

        try {
            const origins = [`${geoStore.zip}`];
            const destinations: string[] = [];
            valueDealers.value.forEach((dealer) => {
                destinations.push(
                    `${dealer.address.street} ${dealer.address.city} ${dealer.address.state} ${dealer.address.zip}`
                );
            });

            // make query to pass to google
            const query = {
                origins,
                destinations,
                unitSystem: google.maps.UnitSystem.IMPERIAL,
                travelMode: google.maps.TravelMode.DRIVING,
            };

            // get the distances
            const distMatrixService = new google.maps.DistanceMatrixService();
            distMatrixService.getDistanceMatrix(query, (response, status) => {
                if (response && status === 'OK') {
                    const distances = response.rows[0].elements;
                    UPDATE_DISTANCES(distances);
                } else {
                    throw new Error('Distance fetch did not work');
                }
            });
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
        }
    };

    return {
        all,
        valueDealers,
        currentDealer,
        currentDealerDisclaimer,
        dealerByArkona,
        dealersByArkonas,
        dealersByMake,
        valueDealerByArkona,
        valueDealerByName,
        valueDealerDistanceMap,
        allMakes,
        siteName,
        siteUrl,
        dealerTimeZone,
        GET_DEALER,
        GET_ALL_DEALERS,
        GET_DEALERS_BY_CITY,
        GET_DEALERS_BY_STATE,
        GET_DEALERS_BY_PHOTOLOCATION,
        GET_DEALERS_BY_MAKE,
        GET_VALUE_DEALERS,
        GET_MAP_INFO,
        UPDATE_DISTANCES,
        GET_DISTANCES_FROM_ZIP,
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useDealersStore, import.meta.hot));
}
