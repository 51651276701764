import compare from 'just-compare';

export default defineNuxtRouteMiddleware((to, from) => {
    /**
     * This middleware is only needed on the client
     * https://nuxt.com/docs/guide/directory-structure/middleware#when-middleware-runs
     */
    if (process.server) return;

    const pathsAreEqual = to.path === from.path;
    const queriesAreEqual = compare(to.query, from.query);
    const debug = process.env.NODE_ENV !== 'production';

    if (pathsAreEqual && !queriesAreEqual) {
        // Do Nothing because SRP filter changes change the queries
    } else {
        const appConfig = useAppConfig();

        const dataToPush = {
            event: appConfig.certificationOptions.gtm.pageTrackingEvent,
            pageTitle:
                (typeof document !== 'undefined' && document.title) || '',
            pageType: 'PageView',
            pageUrl: to.fullPath,
            routeName: to.name ? to.name.toString() : '',
        };

        if (debug) {
            // eslint-disable-next-line no-console
            console.log(dataToPush);
        } else {
            window.dataLayer.push(dataToPush);
        }
    }
});
