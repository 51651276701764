export class InternalBaseApi {
    protected transformOptions = async (
        options: RequestInit
    ): Promise<RequestInit> => {
        // Don't use $auth when on server
        if (typeof useNuxtApp === 'undefined') {
            return Promise.resolve(options);
        }

        const { $auth } = useNuxtApp();
        if ($auth && $auth.isAuthenticated.value) {
            const token = await $auth.getAccessTokenSilently();

            if (token && options.headers) {
                // @ts-ignore
                options.headers.Authorization = 'Bearer ' + token;
            }
        }

        return Promise.resolve(options);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getBaseUrl = (localUrl = 'http://localhost:10504', constructorUrl = '') => {
        if (constructorUrl) return constructorUrl;

        // Uncomment if running Public API locally
        // if (process.env.NODE_ENV === 'development') {
        //     return localUrl;
        // }

        return 'https://apiv2.chapmanapps.com';
    };
}
