/**
 * This file defines a wrapper for each API class from the public API.
 *
 * Why do we need to do this?
 * NSwag is used in the public api C# project to generate the api classes for the front-end.
 * However the NSwag generator assumes that it has access to `window.fetch`.
 * `window.fetch` does not exist by default in SSR context.
 * So for each api class, we extend it and give it the local `fetch` object.
 */

import {
    InternalAuth0Api,
    InternalCaresApi,
    InternalChapmanConnectApi,
    InternalChatApi,
    InternalCollisionApi,
    InternalDealersApi,
    InternalEventsApi,
    InternalFavoritesApi,
    InternalFinanceServicesApi,
    InternalFormApi,
    InternalInventoryApi,
    InternalHealthCheckApi,
    InternalKbbApi,
    InternalMarketingAssetsApi,
    InternalMockDataApi,
    InternalPaymentCalculatorApi,
    InternalSeoManagerApi,
    InternalServiceAppointmentApi,
    InternalSpecialsApi,
    InternalVehiclesApi,
} from './apiGenerated';

// Define the `http` object that NSwag APIs use to figure out which `fetch` to use
const http: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> } =
    { fetch: (...args) => fetch(...args) };

// By extending and redefining the constructor for an API class, we can
// set the default construction settings so components don't need to do it.
export class Auth0Api extends InternalAuth0Api {
    constructor() {
        super(undefined, http);
    }
}
export class CaresApi extends InternalCaresApi {
    constructor() {
        super(undefined, http);
    }
}
export class ChapmanConnectApi extends InternalChapmanConnectApi {
    constructor() {
        super(undefined, http);
    }
}
export class ChatApi extends InternalChatApi {
    constructor() {
        super(undefined, http);
    }
}
export class CollisionApi extends InternalCollisionApi {
    constructor() {
        super(undefined, http);
    }
}
export class DealersApi extends InternalDealersApi {
    constructor() {
        super(undefined, http);
    }
}
export class EventsApi extends InternalEventsApi {
    constructor() {
        super(undefined, http);
    }
}
export class FavoritesApi extends InternalFavoritesApi {
    constructor() {
        super(undefined, http);
    }
}
export class FinanceServicesApi extends InternalFinanceServicesApi {
    constructor(arkona: SiteArkona) {
        super(undefined, http);

        const sessionId = getSessionId(arkona);
        if (!sessionId) return;

        this.createCreditApplication = async (
            postData: FinanceServicesCreditAppRequest
        ) => {
            postData.mediaType = window.innerWidth > 650 ? 'WEB' : 'MOBILE';
            postData.referralUrl = window.location.href;
            const session = getCertificationSession(sessionId);
            return await super.createCreditApplication({
                ...postData,
                ...(session || {}),
            });
        };
    }
}
export class FormApi extends InternalFormApi {
    constructor(arkona: SiteArkona) {
        super(undefined, http);

        const sessionId = getSessionId(arkona);
        if (!sessionId) return;

        this.saveContactForm = async (postData: FormContact) => {
            postData.mediaType = window.innerWidth > 650 ? 'WEB' : 'MOBILE';
            postData.referralUrl = window.location.href;
            const session = getCertificationSession(sessionId);
            await super.saveContactForm({ ...postData, ...(session || {}) });
        };

        this.saveCCPAForm = async (postData: FormCCPA) => {
            postData.mediaType = window.innerWidth > 650 ? 'WEB' : 'MOBILE';
            postData.referralUrl = window.location.href;
            const session = getCertificationSession(sessionId);
            await super.saveCCPAForm({ ...postData, ...(session || {}) });
        };

        this.saveServiceForm = async (postData: FormBaseDetails) => {
            postData.mediaType = window.innerWidth > 650 ? 'WEB' : 'MOBILE';
            postData.referralUrl = window.location.href;
            const session = getCertificationSession(sessionId);
            await super.saveServiceForm({ ...postData, ...(session || {}) });
        };

        this.saveMdxReserveForm = async (postData: FormBaseDetails) => {
            postData.mediaType = window.innerWidth > 650 ? 'WEB' : 'MOBILE';
            postData.referralUrl = window.location.href;
            const session = getCertificationSession(sessionId);
            await super.saveMdxReserveForm({ ...postData, ...(session || {}) });
        };

        this.savePartsForm = async (postData: FormParts) => {
            postData.mediaType = window.innerWidth > 650 ? 'WEB' : 'MOBILE';
            postData.referralUrl = window.location.href;
            const session = getCertificationSession(sessionId);
            await super.savePartsForm({ ...postData, ...(session || {}) });
        };

        this.saveRsvpForm = async (postData: FormRsvp) => {
            postData.mediaType = window.innerWidth > 650 ? 'WEB' : 'MOBILE';
            postData.referralUrl = window.location.href;
            const session = getCertificationSession(sessionId);
            await super.saveRsvpForm({ ...postData, ...(session || {}) });
        };

        this.saveReviewForm = async (postData: FormReviewDetail) => {
            postData.mediaType = window.innerWidth > 650 ? 'WEB' : 'MOBILE';
            postData.referralUrl = window.location.href;
            const session = getCertificationSession(sessionId);
            await super.saveReviewForm({ ...postData, ...(session || {}) });
        };
    }
}
export class InventoryApi extends InternalInventoryApi {
    constructor() {
        super(undefined, http);
    }
}
export class HealthCheckApi extends InternalHealthCheckApi {
    constructor() {
        super(undefined, http);
    }
}
export class KbbApi extends InternalKbbApi {
    constructor(arkona: SiteArkona) {
        super(undefined, http);

        const sessionId = getSessionId(arkona);
        if (!sessionId) return;

        this.acceptOffer = async (postData: KbbAcceptOfferRequest) => {
            postData.mediaType = window.innerWidth > 650 ? 'WEB' : 'MOBILE';
            postData.referralUrl = window.location.href;
            const session = getCertificationSession(sessionId);
            return await super.acceptOffer({ ...postData, ...(session || {}) });
        };
    }
}
export class MarketingAssetsApi extends InternalMarketingAssetsApi {
    constructor() {
        super(undefined, http);
    }
}
export class MockDataApi extends InternalMockDataApi {
    constructor() {
        super(undefined, http);
    }
}
export class PaymentCalculatorApi extends InternalPaymentCalculatorApi {
    constructor() {
        super(undefined, http);
    }
}
export class SeoManagerApi extends InternalSeoManagerApi {
    constructor() {
        super(undefined, http);
    }
}
export class ServiceAppointmentApi extends InternalServiceAppointmentApi {
    constructor() {
        super(undefined, http);
    }
}
export class SpecialsApi extends InternalSpecialsApi {
    constructor() {
        super(undefined, http);
    }
}
export class VehiclesApi extends InternalVehiclesApi {
    constructor() {
        super(undefined, http);
    }
}
