export const variables: Variables = {
    api: {
        dealers: {
            city: '',
            make: '',
            stateCode: '',
            photoLocation: '',
        },
    },

    arkonas: {
        sales: '',
        site: 'hcag',
        new: 'store',
        used: 'store',
        tradeForm: '',
        fleet: '',
    },

    hub: {
        isHubSite: false,

        isAz: false,
        isChoice: false,
        isTucson: false,
        isValue: false,
        location: '',
    },

    dealerShort: '',
    isUsedSite: false,

    appointmentOptions: {
        reverse: false,
    },

    breadcrumbsBarOptions: {
        uppercase: false,
    },

    carLinkOptions: {
        hideMsrpLabel: false,
        msrpLabel: 'Was',
        scrollToDisclaimer: false,
    },

    certificationOptions: {
        gtm: {
            pageTrackingEvent: 'nuxtRoute',
        },
    },

    communityOptions: {
        grayBgIndex: 'even',
    },

    chipsDisplayOptions: {
        usedLabel: 'Used',
    },

    clearanceOptions: {
        year: 0,
        make: '',
    },

    detailOptions: {
        hideEstimatesBehindGate: false,
        showVdpDealerDetailsByCar: false,

        windowStickerUrls: {
            new: {
                display: '',
            },
            used: {
                display: '',
            },
        },

        isVdpGated: false,
        includeCustomRateOption: false,
    },

    filtersOptions: {
        defaultToPopularitySort: false,
        typeLabel: '',
        usedLabel: 'Used',
    },

    footerFormOptions: {
        centerText: false,
        flavorText: '',
    },

    footerOptions: {
        disclaimers: [],
        showBbbSeal: false,
    },

    headingOptions: {
        splitName: ['', ''],
        uppercase: false,
        useFullWidthBar: false,
    },

    homeSearchRefineOptions: {
        flavor: '',
        uppercase: true,
        usedLabel: 'Used',
    },

    infoBanners: [],

    inventory: {
        sortMakeToTop: '',
    },

    keys: {
        maps: '',
        careers: [],
    },

    layoutOptions: {
        hiddenBackToTopPages: [],
        hiddenFooterFormPages: [],
    },

    navOptions: {
        hideMakeModelLinks: false,
        hideMake: false,
    },

    pricingSectionOptions: {
        scrollToDisclaimer: false,
    },

    reviewLinks: {
        google: '',
        yelp: '',
    },

    searchOptions: {
        cpoFilterByMake: false,
        searchCards: [],
        matchingCards: [],
    },

    seoOptions: {
        city: '',
        name: '',
        region: '',
        seoDealerId: 0,
        state: '',
        stateShort: '',
        url: '',
    },

    serviceOptions: {
        externalSchedulingUrl: '',
    },

    specialsOptions: {
        new: {
            useNational: false,
        },
        used: {
            useDay45: false,
            label: 'Used',
            filterByMake: false,
        },
        cpo: {
            useDay45: false,
            filterByMake: false,
        },
    },

    staffOptions: {
        showContactDetails: false,

        specialDepartments: [],
    },

    tagOptions: {
        grayscale: false,
    },

    theme: 'default',
};

export default defineAppConfig({ ...variables });
