import { createAuth0 } from '@auth0/auth0-vue';

export default defineNuxtPlugin((nuxtApp) => {
    let auth: ReturnType<typeof createAuth0> | null = null;

    // Wrap in try catch because it may try to login on initial load if auth0 cookies exist
    // It may possibly fail on older browsers / third party weirdness
    try {
        auth = createAuth0({
            domain: 'chapmanauto.us.auth0.com',
            clientId: nuxtApp.$config.public.authClientId ?? '',
            authorizationParams: {
                audience: 'https://api.chapmanchoice.com',
                redirect_uri: window.location.origin,
            },
        });
        nuxtApp.vueApp.use(auth);
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    }

    return {
        provide: {
            auth,
        },
    };
});
