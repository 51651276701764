interface SessionRecord {
    utm_source: string | null;
    utm_medium: string | null;
    utm_campaign: string | null;
    ads_adgroup: string | null;
    ads_adid: string | null;
    ads_digadprovider: string | null;
}

type SessionId = 'jdPowerSession' | 'hondaSession';

export const saveCertificationSession = (sessionId: SessionId) => {
    const query = new URLSearchParams(window.location.search);

    if (
        query.has('utm_source') &&
        query.has('utm_medium') &&
        query.has('utm_campaign') &&
        query.has('ads_adgroup') &&
        query.has('ads_adid') &&
        query.has('ads_digadprovider')
    ) {
        const utm_source = query.get('utm_source');
        const utm_medium = query.get('utm_medium');
        const utm_campaign = query.get('utm_campaign');
        const ads_adgroup = query.get('ads_adgroup');
        const ads_adid = query.get('ads_adid');
        const ads_digadprovider = query.get('ads_digadprovider');

        const session: SessionRecord = {
            utm_source,
            utm_medium,
            utm_campaign,
            ads_adgroup,
            ads_adid,
            ads_digadprovider,
        };
        const sessionString = JSON.stringify(session);
        sessionStorage.setItem(sessionId, sessionString);
    }
};

export const getSessionId = (arkona: SiteArkona): SessionId | null => {
    switch (arkona) {
        case 'cao':
            return 'jdPowerSession';
        case 'can':
            return 'hondaSession';
        default:
            return null;
    }
};

export const getCertificationSession = (sessionId: SessionId) => {
    const sessionString = sessionStorage.getItem(sessionId);
    if (sessionString) {
        return JSON.parse(sessionString) as SessionRecord;
    }
    return null;
};

// The random casing of these keys is intentional
interface HondaEvent {
    [key: string]: string | number | undefined;
    event?: string;
    Dealerid?: string;
    hform?: string;
    formclass?: string;
    'content-group'?: string;
    VCategory?: string;
    VViewed?: number;
    VType?: string;
    Vmake?: string;
    Vmodel?: string;
    VTrim?: string;
    VBodystyle?: string;
    VYear?: number;
    Vin?: string;
}

export const createHondaEvent = (rawEvent: HondaEvent) => {
    const { event, ...attrs } = rawEvent;
    const hondaEvent: HondaEvent = {};

    if (event) {
        hondaEvent.event = event;
    }

    const keys = Object.keys(attrs);
    keys.forEach((key) => {
        hondaEvent[`autodata.honda.${key}`] = attrs[key];
    });

    return hondaEvent;
};
