import { default as automobility_45disability_45assistanceJlU6ta6uZ6Meta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-dealers/retail-dealers-dodge/pages/automobility-disability-assistance.vue?macro=true";
import { default as california_45ccpa_45formeqICk1I8hGMeta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/california-ccpa-form.vue?macro=true";
import { default as adminv89Drphyf1Meta } from "/home/runner/work/sites.retail/sites.retail/layers/base/pages/careers/admin.vue?macro=true";
import { default as indexz3wXxhavtGMeta } from "/home/runner/work/sites.retail/sites.retail/layers/base/pages/careers/index.vue?macro=true";
import { default as positionswcKnKpOmtLMeta } from "/home/runner/work/sites.retail/sites.retail/layers/base/pages/careers/positions.vue?macro=true";
import { default as sales6flVgc9pdeMeta } from "/home/runner/work/sites.retail/sites.retail/layers/base/pages/careers/sales.vue?macro=true";
import { default as serviceHob57sRVZJMeta } from "/home/runner/work/sites.retail/sites.retail/layers/base/pages/careers/service.vue?macro=true";
import { default as certified_45preowned_45detailsI9AjpZkjLoMeta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-dealers/retail-dealers-dodge/pages/certified-preowned-details.vue?macro=true";
import { default as communityu3Wl0TiKMMMeta } from "/home/runner/work/sites.retail/sites.retail/layers/base/pages/community.vue?macro=true";
import { default as contactFSUKFlxv0CMeta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-dealers/retail-dealers-base/pages/contact.vue?macro=true";
import { default as index325YN38vkYMeta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/detail/[type]/[year]/[make]/[model]/[stock]/[[uniqueArkona]]/index.vue?macro=true";
import { default as availability_45notificationpf9JtqG2wVMeta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/form/availability-notification.vue?macro=true";
import { default as financeOa0NQGQIw5Meta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/form/finance.vue?macro=true";
import { default as similar_45vehicles0qSJpdrSlZMeta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/form/similar-vehicles.vue?macro=true";
import { default as submit_45reviewK2iUYePQU5Meta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/form/submit-review.vue?macro=true";
import { default as vehicle_45appointmentMj8HPXX2VdMeta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/form/vehicle-appointment.vue?macro=true";
import { default as vehicle_45custom_45orderfEP6zUBBu8Meta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/form/vehicle-custom-order.vue?macro=true";
import { default as indexFiVuN8WnCcMeta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/form/vehicle-finance/[leadArkona]/[vin]/index.vue?macro=true";
import { default as vehicle_45interestyrxRZyW006Meta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/form/vehicle-interest.vue?macro=true";
import { default as _91arkona_93ZgyKObfaN2Meta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/health-check/[arkona].vue?macro=true";
import { default as indexaJCbOE0h7iMeta } from "/home/runner/work/sites.retail/sites.retail/sites/dealers/cyd/pages/index.vue?macro=true";
import { default as military_45bonus_45cashbKlISXMFuXMeta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-dealers/retail-dealers-dodge/pages/military-bonus-cash.vue?macro=true";
import { default as model_45year_45blowoutr48IFHlB6FMeta } from "/home/runner/work/sites.retail/sites.retail/sites/dealers/cyd/pages/model-year-blowout.vue?macro=true";
import { default as parts_45catalog0JhR10t1kwMeta } from "/home/runner/work/sites.retail/sites.retail/sites/dealers/cyd/pages/parts-catalog.vue?macro=true";
import { default as partsbwKqb6kEghMeta } from "/home/runner/work/sites.retail/sites.retail/sites/dealers/cyd/pages/parts.vue?macro=true";
import { default as privacy_45policyMBVexVqKtsMeta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-dealers/retail-dealers-base/pages/privacy-policy.vue?macro=true";
import { default as reviewsgjsIP3MvQOMeta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-dealers/retail-dealers-base/pages/reviews.vue?macro=true";
import { default as index4lCMp8g1gWMeta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/search/[[type]]/[[make]]/[[model]]/index.vue?macro=true";
import { default as detailsgjCStelEMxMeta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/sell-my-car/details.vue?macro=true";
import { default as indextrrx8paRP9Meta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/sell-my-car/index.vue?macro=true";
import { default as _91id_93HkSt9qkATgMeta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/sell-my-car/offer/[id].vue?macro=true";
import { default as service_45and_45repair_45financingxjp4E3EGTCMeta } from "/home/runner/work/sites.retail/sites.retail/sites/dealers/cyd/pages/service-and-repair-financing.vue?macro=true";
import { default as couponsMTgkooXg9CMeta } from "/home/runner/work/sites.retail/sites.retail/sites/dealers/cyd/pages/service/coupons.vue?macro=true";
import { default as expressZ22M1g4jgiMeta } from "/home/runner/work/sites.retail/sites.retail/sites/dealers/cyd/pages/service/express.vue?macro=true";
import { default as indexCoeLFoOF7oMeta } from "/home/runner/work/sites.retail/sites.retail/sites/dealers/cyd/pages/service/index.vue?macro=true";
import { default as jeep_45waveqMRoFxcgi6Meta } from "/home/runner/work/sites.retail/sites.retail/sites/dealers/cyd/pages/service/jeep-wave.vue?macro=true";
import { default as protectioncmcVkNwl6NMeta } from "/home/runner/work/sites.retail/sites.retail/sites/dealers/cyd/pages/service/protection.vue?macro=true";
import { default as slot_45testQ5KAj5F31eMeta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/service/slot-test.vue?macro=true";
import { default as sitemapbAkKhw1LFKMeta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/sitemap.vue?macro=true";
import { default as indexCFVNJV4eluMeta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-dealers/retail-dealers-base/pages/specials/[type]/index.vue?macro=true";
import { default as staff_45directory4bylEfY3UEMeta } from "/home/runner/work/sites.retail/sites.retail/layers/retail/retail-dealers/retail-dealers-base/pages/staff-directory.vue?macro=true";
import { default as sitessc7D511zBMMeta } from "/home/runner/work/sites.retail/sites.retail/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as sitessc7D511zBM } from "/home/runner/work/sites.retail/sites.retail/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: automobility_45disability_45assistanceJlU6ta6uZ6Meta?.name ?? "automobility-disability-assistance",
    path: automobility_45disability_45assistanceJlU6ta6uZ6Meta?.path ?? "/automobility-disability-assistance",
    meta: automobility_45disability_45assistanceJlU6ta6uZ6Meta || {},
    alias: automobility_45disability_45assistanceJlU6ta6uZ6Meta?.alias || [],
    redirect: automobility_45disability_45assistanceJlU6ta6uZ6Meta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-dealers/retail-dealers-dodge/pages/automobility-disability-assistance.vue").then(m => m.default || m)
  },
  {
    name: california_45ccpa_45formeqICk1I8hGMeta?.name ?? "california-ccpa-form",
    path: california_45ccpa_45formeqICk1I8hGMeta?.path ?? "/california-ccpa-form",
    meta: california_45ccpa_45formeqICk1I8hGMeta || {},
    alias: california_45ccpa_45formeqICk1I8hGMeta?.alias || [],
    redirect: california_45ccpa_45formeqICk1I8hGMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/california-ccpa-form.vue").then(m => m.default || m)
  },
  {
    name: adminv89Drphyf1Meta?.name ?? "careers-admin",
    path: adminv89Drphyf1Meta?.path ?? "/careers/admin",
    meta: adminv89Drphyf1Meta || {},
    alias: adminv89Drphyf1Meta?.alias || [],
    redirect: adminv89Drphyf1Meta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/base/pages/careers/admin.vue").then(m => m.default || m)
  },
  {
    name: indexz3wXxhavtGMeta?.name ?? "careers",
    path: indexz3wXxhavtGMeta?.path ?? "/careers",
    meta: indexz3wXxhavtGMeta || {},
    alias: indexz3wXxhavtGMeta?.alias || [],
    redirect: indexz3wXxhavtGMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/base/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: positionswcKnKpOmtLMeta?.name ?? "careers-positions",
    path: positionswcKnKpOmtLMeta?.path ?? "/careers/positions",
    meta: positionswcKnKpOmtLMeta || {},
    alias: positionswcKnKpOmtLMeta?.alias || [],
    redirect: positionswcKnKpOmtLMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/base/pages/careers/positions.vue").then(m => m.default || m)
  },
  {
    name: sales6flVgc9pdeMeta?.name ?? "careers-sales",
    path: sales6flVgc9pdeMeta?.path ?? "/careers/sales",
    meta: sales6flVgc9pdeMeta || {},
    alias: sales6flVgc9pdeMeta?.alias || [],
    redirect: sales6flVgc9pdeMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/base/pages/careers/sales.vue").then(m => m.default || m)
  },
  {
    name: serviceHob57sRVZJMeta?.name ?? "careers-service",
    path: serviceHob57sRVZJMeta?.path ?? "/careers/service",
    meta: serviceHob57sRVZJMeta || {},
    alias: serviceHob57sRVZJMeta?.alias || [],
    redirect: serviceHob57sRVZJMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/base/pages/careers/service.vue").then(m => m.default || m)
  },
  {
    name: certified_45preowned_45detailsI9AjpZkjLoMeta?.name ?? "certified-preowned-details",
    path: certified_45preowned_45detailsI9AjpZkjLoMeta?.path ?? "/certified-preowned-details",
    meta: certified_45preowned_45detailsI9AjpZkjLoMeta || {},
    alias: certified_45preowned_45detailsI9AjpZkjLoMeta?.alias || [],
    redirect: certified_45preowned_45detailsI9AjpZkjLoMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-dealers/retail-dealers-dodge/pages/certified-preowned-details.vue").then(m => m.default || m)
  },
  {
    name: communityu3Wl0TiKMMMeta?.name ?? "community",
    path: communityu3Wl0TiKMMMeta?.path ?? "/community",
    meta: communityu3Wl0TiKMMMeta || {},
    alias: communityu3Wl0TiKMMMeta?.alias || [],
    redirect: communityu3Wl0TiKMMMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/base/pages/community.vue").then(m => m.default || m)
  },
  {
    name: contactFSUKFlxv0CMeta?.name ?? "contact",
    path: contactFSUKFlxv0CMeta?.path ?? "/contact",
    meta: contactFSUKFlxv0CMeta || {},
    alias: contactFSUKFlxv0CMeta?.alias || [],
    redirect: contactFSUKFlxv0CMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-dealers/retail-dealers-base/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: index325YN38vkYMeta?.name ?? "detail-type-year-make-model-stock-uniqueArkona",
    path: index325YN38vkYMeta?.path ?? "/detail/:type()/:year()/:make()/:model()/:stock()/:uniqueArkona?",
    meta: index325YN38vkYMeta || {},
    alias: index325YN38vkYMeta?.alias || [],
    redirect: index325YN38vkYMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/detail/[type]/[year]/[make]/[model]/[stock]/[[uniqueArkona]]/index.vue").then(m => m.default || m)
  },
  {
    name: availability_45notificationpf9JtqG2wVMeta?.name ?? "form-availability-notification",
    path: availability_45notificationpf9JtqG2wVMeta?.path ?? "/form/availability-notification",
    meta: availability_45notificationpf9JtqG2wVMeta || {},
    alias: availability_45notificationpf9JtqG2wVMeta?.alias || [],
    redirect: availability_45notificationpf9JtqG2wVMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/form/availability-notification.vue").then(m => m.default || m)
  },
  {
    name: financeOa0NQGQIw5Meta?.name ?? "form-finance",
    path: financeOa0NQGQIw5Meta?.path ?? "/form/finance",
    meta: financeOa0NQGQIw5Meta || {},
    alias: financeOa0NQGQIw5Meta?.alias || [],
    redirect: financeOa0NQGQIw5Meta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/form/finance.vue").then(m => m.default || m)
  },
  {
    name: similar_45vehicles0qSJpdrSlZMeta?.name ?? "form-similar-vehicles",
    path: similar_45vehicles0qSJpdrSlZMeta?.path ?? "/form/similar-vehicles",
    meta: similar_45vehicles0qSJpdrSlZMeta || {},
    alias: similar_45vehicles0qSJpdrSlZMeta?.alias || [],
    redirect: similar_45vehicles0qSJpdrSlZMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/form/similar-vehicles.vue").then(m => m.default || m)
  },
  {
    name: submit_45reviewK2iUYePQU5Meta?.name ?? "form-submit-review",
    path: submit_45reviewK2iUYePQU5Meta?.path ?? "/form/submit-review",
    meta: submit_45reviewK2iUYePQU5Meta || {},
    alias: submit_45reviewK2iUYePQU5Meta?.alias || [],
    redirect: submit_45reviewK2iUYePQU5Meta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/form/submit-review.vue").then(m => m.default || m)
  },
  {
    name: vehicle_45appointmentMj8HPXX2VdMeta?.name ?? "form-vehicle-appointment",
    path: vehicle_45appointmentMj8HPXX2VdMeta?.path ?? "/form/vehicle-appointment",
    meta: vehicle_45appointmentMj8HPXX2VdMeta || {},
    alias: vehicle_45appointmentMj8HPXX2VdMeta?.alias || [],
    redirect: vehicle_45appointmentMj8HPXX2VdMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/form/vehicle-appointment.vue").then(m => m.default || m)
  },
  {
    name: vehicle_45custom_45orderfEP6zUBBu8Meta?.name ?? "form-vehicle-custom-order",
    path: vehicle_45custom_45orderfEP6zUBBu8Meta?.path ?? "/form/vehicle-custom-order",
    meta: vehicle_45custom_45orderfEP6zUBBu8Meta || {},
    alias: vehicle_45custom_45orderfEP6zUBBu8Meta?.alias || [],
    redirect: vehicle_45custom_45orderfEP6zUBBu8Meta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/form/vehicle-custom-order.vue").then(m => m.default || m)
  },
  {
    name: indexFiVuN8WnCcMeta?.name ?? "form-vehicle-finance-leadArkona-vin",
    path: indexFiVuN8WnCcMeta?.path ?? "/form/vehicle-finance/:leadArkona()/:vin()",
    meta: indexFiVuN8WnCcMeta || {},
    alias: indexFiVuN8WnCcMeta?.alias || [],
    redirect: indexFiVuN8WnCcMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/form/vehicle-finance/[leadArkona]/[vin]/index.vue").then(m => m.default || m)
  },
  {
    name: vehicle_45interestyrxRZyW006Meta?.name ?? "form-vehicle-interest",
    path: vehicle_45interestyrxRZyW006Meta?.path ?? "/form/vehicle-interest",
    meta: vehicle_45interestyrxRZyW006Meta || {},
    alias: vehicle_45interestyrxRZyW006Meta?.alias || [],
    redirect: vehicle_45interestyrxRZyW006Meta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/form/vehicle-interest.vue").then(m => m.default || m)
  },
  {
    name: _91arkona_93ZgyKObfaN2Meta?.name ?? "health-check-arkona",
    path: _91arkona_93ZgyKObfaN2Meta?.path ?? "/health-check/:arkona()",
    meta: _91arkona_93ZgyKObfaN2Meta || {},
    alias: _91arkona_93ZgyKObfaN2Meta?.alias || [],
    redirect: _91arkona_93ZgyKObfaN2Meta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/health-check/[arkona].vue").then(m => m.default || m)
  },
  {
    name: indexaJCbOE0h7iMeta?.name ?? "index",
    path: indexaJCbOE0h7iMeta?.path ?? "/",
    meta: indexaJCbOE0h7iMeta || {},
    alias: indexaJCbOE0h7iMeta?.alias || [],
    redirect: indexaJCbOE0h7iMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/sites/dealers/cyd/pages/index.vue").then(m => m.default || m)
  },
  {
    name: military_45bonus_45cashbKlISXMFuXMeta?.name ?? "military-bonus-cash",
    path: military_45bonus_45cashbKlISXMFuXMeta?.path ?? "/military-bonus-cash",
    meta: military_45bonus_45cashbKlISXMFuXMeta || {},
    alias: military_45bonus_45cashbKlISXMFuXMeta?.alias || [],
    redirect: military_45bonus_45cashbKlISXMFuXMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-dealers/retail-dealers-dodge/pages/military-bonus-cash.vue").then(m => m.default || m)
  },
  {
    name: model_45year_45blowoutr48IFHlB6FMeta?.name ?? "model-year-blowout",
    path: model_45year_45blowoutr48IFHlB6FMeta?.path ?? "/model-year-blowout",
    meta: model_45year_45blowoutr48IFHlB6FMeta || {},
    alias: model_45year_45blowoutr48IFHlB6FMeta?.alias || [],
    redirect: model_45year_45blowoutr48IFHlB6FMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/sites/dealers/cyd/pages/model-year-blowout.vue").then(m => m.default || m)
  },
  {
    name: parts_45catalog0JhR10t1kwMeta?.name ?? "parts-catalog",
    path: parts_45catalog0JhR10t1kwMeta?.path ?? "/parts-catalog",
    meta: parts_45catalog0JhR10t1kwMeta || {},
    alias: parts_45catalog0JhR10t1kwMeta?.alias || [],
    redirect: parts_45catalog0JhR10t1kwMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/sites/dealers/cyd/pages/parts-catalog.vue").then(m => m.default || m)
  },
  {
    name: partsbwKqb6kEghMeta?.name ?? "parts",
    path: partsbwKqb6kEghMeta?.path ?? "/parts",
    meta: partsbwKqb6kEghMeta || {},
    alias: partsbwKqb6kEghMeta?.alias || [],
    redirect: partsbwKqb6kEghMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/sites/dealers/cyd/pages/parts.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyMBVexVqKtsMeta?.name ?? "privacy-policy",
    path: privacy_45policyMBVexVqKtsMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyMBVexVqKtsMeta || {},
    alias: privacy_45policyMBVexVqKtsMeta?.alias || [],
    redirect: privacy_45policyMBVexVqKtsMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-dealers/retail-dealers-base/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: reviewsgjsIP3MvQOMeta?.name ?? "reviews",
    path: reviewsgjsIP3MvQOMeta?.path ?? "/reviews",
    meta: reviewsgjsIP3MvQOMeta || {},
    alias: reviewsgjsIP3MvQOMeta?.alias || [],
    redirect: reviewsgjsIP3MvQOMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-dealers/retail-dealers-base/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: index4lCMp8g1gWMeta?.name ?? "search-type-make-model",
    path: index4lCMp8g1gWMeta?.path ?? "/search/:type?/:make?/:model?",
    meta: index4lCMp8g1gWMeta || {},
    alias: index4lCMp8g1gWMeta?.alias || [],
    redirect: index4lCMp8g1gWMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/search/[[type]]/[[make]]/[[model]]/index.vue").then(m => m.default || m)
  },
  {
    name: detailsgjCStelEMxMeta?.name ?? "sell-my-car-details",
    path: detailsgjCStelEMxMeta?.path ?? "/sell-my-car/details",
    meta: detailsgjCStelEMxMeta || {},
    alias: detailsgjCStelEMxMeta?.alias || [],
    redirect: detailsgjCStelEMxMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/sell-my-car/details.vue").then(m => m.default || m)
  },
  {
    name: indextrrx8paRP9Meta?.name ?? "sell-my-car",
    path: indextrrx8paRP9Meta?.path ?? "/sell-my-car",
    meta: indextrrx8paRP9Meta || {},
    alias: indextrrx8paRP9Meta?.alias || [],
    redirect: indextrrx8paRP9Meta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/sell-my-car/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93HkSt9qkATgMeta?.name ?? "sell-my-car-offer-id",
    path: _91id_93HkSt9qkATgMeta?.path ?? "/sell-my-car/offer/:id()",
    meta: _91id_93HkSt9qkATgMeta || {},
    alias: _91id_93HkSt9qkATgMeta?.alias || [],
    redirect: _91id_93HkSt9qkATgMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/sell-my-car/offer/[id].vue").then(m => m.default || m)
  },
  {
    name: service_45and_45repair_45financingxjp4E3EGTCMeta?.name ?? "service-and-repair-financing",
    path: service_45and_45repair_45financingxjp4E3EGTCMeta?.path ?? "/service-and-repair-financing",
    meta: service_45and_45repair_45financingxjp4E3EGTCMeta || {},
    alias: service_45and_45repair_45financingxjp4E3EGTCMeta?.alias || [],
    redirect: service_45and_45repair_45financingxjp4E3EGTCMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/sites/dealers/cyd/pages/service-and-repair-financing.vue").then(m => m.default || m)
  },
  {
    name: couponsMTgkooXg9CMeta?.name ?? "service-coupons",
    path: couponsMTgkooXg9CMeta?.path ?? "/service/coupons",
    meta: couponsMTgkooXg9CMeta || {},
    alias: couponsMTgkooXg9CMeta?.alias || [],
    redirect: couponsMTgkooXg9CMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/sites/dealers/cyd/pages/service/coupons.vue").then(m => m.default || m)
  },
  {
    name: expressZ22M1g4jgiMeta?.name ?? "service-express",
    path: expressZ22M1g4jgiMeta?.path ?? "/service/express",
    meta: expressZ22M1g4jgiMeta || {},
    alias: expressZ22M1g4jgiMeta?.alias || [],
    redirect: expressZ22M1g4jgiMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/sites/dealers/cyd/pages/service/express.vue").then(m => m.default || m)
  },
  {
    name: indexCoeLFoOF7oMeta?.name ?? "service",
    path: indexCoeLFoOF7oMeta?.path ?? "/service",
    meta: indexCoeLFoOF7oMeta || {},
    alias: indexCoeLFoOF7oMeta?.alias || [],
    redirect: indexCoeLFoOF7oMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/sites/dealers/cyd/pages/service/index.vue").then(m => m.default || m)
  },
  {
    name: jeep_45waveqMRoFxcgi6Meta?.name ?? "service-jeep-wave",
    path: jeep_45waveqMRoFxcgi6Meta?.path ?? "/service/jeep-wave",
    meta: jeep_45waveqMRoFxcgi6Meta || {},
    alias: jeep_45waveqMRoFxcgi6Meta?.alias || [],
    redirect: jeep_45waveqMRoFxcgi6Meta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/sites/dealers/cyd/pages/service/jeep-wave.vue").then(m => m.default || m)
  },
  {
    name: protectioncmcVkNwl6NMeta?.name ?? "service-protection",
    path: protectioncmcVkNwl6NMeta?.path ?? "/service/protection",
    meta: protectioncmcVkNwl6NMeta || {},
    alias: protectioncmcVkNwl6NMeta?.alias || [],
    redirect: protectioncmcVkNwl6NMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/sites/dealers/cyd/pages/service/protection.vue").then(m => m.default || m)
  },
  {
    name: slot_45testQ5KAj5F31eMeta?.name ?? "service-slot-test",
    path: slot_45testQ5KAj5F31eMeta?.path ?? "/service/slot-test",
    meta: slot_45testQ5KAj5F31eMeta || {},
    alias: slot_45testQ5KAj5F31eMeta?.alias || [],
    redirect: slot_45testQ5KAj5F31eMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/service/slot-test.vue").then(m => m.default || m)
  },
  {
    name: sitemapbAkKhw1LFKMeta?.name ?? "sitemap",
    path: sitemapbAkKhw1LFKMeta?.path ?? "/sitemap",
    meta: sitemapbAkKhw1LFKMeta || {},
    alias: sitemapbAkKhw1LFKMeta?.alias || [],
    redirect: sitemapbAkKhw1LFKMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-base/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: indexCFVNJV4eluMeta?.name ?? "specials-type",
    path: indexCFVNJV4eluMeta?.path ?? "/specials/:type()",
    meta: indexCFVNJV4eluMeta || {},
    alias: indexCFVNJV4eluMeta?.alias || [],
    redirect: indexCFVNJV4eluMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-dealers/retail-dealers-base/pages/specials/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: staff_45directory4bylEfY3UEMeta?.name ?? "staff-directory",
    path: staff_45directory4bylEfY3UEMeta?.path ?? "/staff-directory",
    meta: staff_45directory4bylEfY3UEMeta || {},
    alias: staff_45directory4bylEfY3UEMeta?.alias || [],
    redirect: staff_45directory4bylEfY3UEMeta?.redirect,
    component: () => import("/home/runner/work/sites.retail/sites.retail/layers/retail/retail-dealers/retail-dealers-base/pages/staff-directory.vue").then(m => m.default || m)
  },
  {
    name: sitessc7D511zBMMeta?.name ?? undefined,
    path: sitessc7D511zBMMeta?.path ?? "/all-inventory/jeep.htm",
    meta: sitessc7D511zBMMeta || {},
    alias: sitessc7D511zBMMeta?.alias || [],
    redirect: sitessc7D511zBMMeta?.redirect,
    component: sitessc7D511zBM
  },
  {
    name: sitessc7D511zBMMeta?.name ?? undefined,
    path: sitessc7D511zBMMeta?.path ?? "/featured-vehicles/new.htm",
    meta: sitessc7D511zBMMeta || {},
    alias: sitessc7D511zBMMeta?.alias || [],
    redirect: sitessc7D511zBMMeta?.redirect,
    component: sitessc7D511zBM
  },
  {
    name: sitessc7D511zBMMeta?.name ?? undefined,
    path: sitessc7D511zBMMeta?.path ?? "/financing/application-short.htm",
    meta: sitessc7D511zBMMeta || {},
    alias: sitessc7D511zBMMeta?.alias || [],
    redirect: sitessc7D511zBMMeta?.redirect,
    component: sitessc7D511zBM
  },
  {
    name: sitessc7D511zBMMeta?.name ?? undefined,
    path: sitessc7D511zBMMeta?.path ?? "/new-inventory/index.htm",
    meta: sitessc7D511zBMMeta || {},
    alias: sitessc7D511zBMMeta?.alias || [],
    redirect: sitessc7D511zBMMeta?.redirect,
    component: sitessc7D511zBM
  },
  {
    name: sitessc7D511zBMMeta?.name ?? undefined,
    path: sitessc7D511zBMMeta?.path ?? "/trade-in-form.htm",
    meta: sitessc7D511zBMMeta || {},
    alias: sitessc7D511zBMMeta?.alias || [],
    redirect: sitessc7D511zBMMeta?.redirect,
    component: sitessc7D511zBM
  },
  {
    name: sitessc7D511zBMMeta?.name ?? undefined,
    path: sitessc7D511zBMMeta?.path ?? "/we-buy-cars.htm",
    meta: sitessc7D511zBMMeta || {},
    alias: sitessc7D511zBMMeta?.alias || [],
    redirect: sitessc7D511zBMMeta?.redirect,
    component: sitessc7D511zBM
  },
  {
    name: sitessc7D511zBMMeta?.name ?? undefined,
    path: sitessc7D511zBMMeta?.path ?? "/contact.htm",
    meta: sitessc7D511zBMMeta || {},
    alias: sitessc7D511zBMMeta?.alias || [],
    redirect: sitessc7D511zBMMeta?.redirect,
    component: sitessc7D511zBM
  },
  {
    name: sitessc7D511zBMMeta?.name ?? undefined,
    path: sitessc7D511zBMMeta?.path ?? "/dealership/testimonials.htm",
    meta: sitessc7D511zBMMeta || {},
    alias: sitessc7D511zBMMeta?.alias || [],
    redirect: sitessc7D511zBMMeta?.redirect,
    component: sitessc7D511zBM
  },
  {
    name: sitessc7D511zBMMeta?.name ?? undefined,
    path: sitessc7D511zBMMeta?.path ?? "/used-specials.htm",
    meta: sitessc7D511zBMMeta || {},
    alias: sitessc7D511zBMMeta?.alias || [],
    redirect: sitessc7D511zBMMeta?.redirect,
    component: sitessc7D511zBM
  },
  {
    name: sitessc7D511zBMMeta?.name ?? undefined,
    path: sitessc7D511zBMMeta?.path ?? "/specials/service.htm",
    meta: sitessc7D511zBMMeta || {},
    alias: sitessc7D511zBMMeta?.alias || [],
    redirect: sitessc7D511zBMMeta?.redirect,
    component: sitessc7D511zBM
  },
  {
    name: sitessc7D511zBMMeta?.name ?? undefined,
    path: sitessc7D511zBMMeta?.path ?? "/parts/index.htm",
    meta: sitessc7D511zBMMeta || {},
    alias: sitessc7D511zBMMeta?.alias || [],
    redirect: sitessc7D511zBMMeta?.redirect,
    component: sitessc7D511zBM
  },
  {
    name: sitessc7D511zBMMeta?.name ?? undefined,
    path: sitessc7D511zBMMeta?.path ?? "/dealership/about.htm",
    meta: sitessc7D511zBMMeta || {},
    alias: sitessc7D511zBMMeta?.alias || [],
    redirect: sitessc7D511zBMMeta?.redirect,
    component: sitessc7D511zBM
  },
  {
    name: sitessc7D511zBMMeta?.name ?? undefined,
    path: sitessc7D511zBMMeta?.path ?? "/parts/mopar.htm",
    meta: sitessc7D511zBMMeta || {},
    alias: sitessc7D511zBMMeta?.alias || [],
    redirect: sitessc7D511zBMMeta?.redirect,
    component: sitessc7D511zBM
  },
  {
    name: sitessc7D511zBMMeta?.name ?? undefined,
    path: sitessc7D511zBMMeta?.path ?? "/dealership/staff.htm",
    meta: sitessc7D511zBMMeta || {},
    alias: sitessc7D511zBMMeta?.alias || [],
    redirect: sitessc7D511zBMMeta?.redirect,
    component: sitessc7D511zBM
  },
  {
    name: sitessc7D511zBMMeta?.name ?? undefined,
    path: sitessc7D511zBMMeta?.path ?? "/jeep-wave.htm",
    meta: sitessc7D511zBMMeta || {},
    alias: sitessc7D511zBMMeta?.alias || [],
    redirect: sitessc7D511zBMMeta?.redirect,
    component: sitessc7D511zBM
  },
  {
    name: sitessc7D511zBMMeta?.name ?? undefined,
    path: sitessc7D511zBMMeta?.path ?? "/at-home-services",
    meta: sitessc7D511zBMMeta || {},
    alias: sitessc7D511zBMMeta?.alias || [],
    redirect: sitessc7D511zBMMeta?.redirect,
    component: sitessc7D511zBM
  },
  {
    name: sitessc7D511zBMMeta?.name ?? undefined,
    path: sitessc7D511zBMMeta?.path ?? "/covid19",
    meta: sitessc7D511zBMMeta || {},
    alias: sitessc7D511zBMMeta?.alias || [],
    redirect: sitessc7D511zBMMeta?.redirect,
    component: sitessc7D511zBM
  },
  {
    name: sitessc7D511zBMMeta?.name ?? undefined,
    path: sitessc7D511zBMMeta?.path ?? "/collision-center",
    meta: sitessc7D511zBMMeta || {},
    alias: sitessc7D511zBMMeta?.alias || [],
    redirect: sitessc7D511zBMMeta?.redirect,
    component: sitessc7D511zBM
  },
  {
    name: sitessc7D511zBMMeta?.name ?? undefined,
    path: sitessc7D511zBMMeta?.path ?? "/mychapman",
    meta: sitessc7D511zBMMeta || {},
    alias: sitessc7D511zBMMeta?.alias || [],
    redirect: sitessc7D511zBMMeta?.redirect,
    component: sitessc7D511zBM
  }
]