import {
    init,
    browserTracingIntegration,
    replayIntegration,
} from '@sentry/vue';
import type { Router } from '#vue-router';

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();

    if (
        import.meta.env.PROD &&
        !!config.public.sentryDsn &&
        !!config.public.sentryRelease &&
        !!config.public.sentryTraceTarget
    ) {
        init({
            app: nuxtApp.vueApp,
            dsn: config.public.sentryDsn,
            release: config.public.sentryRelease,
            environment: import.meta.env.MODE,
            logErrors: true,
            integrations: [
                browserTracingIntegration({
                    router: nuxtApp.$router as Router,
                }),
                replayIntegration(),
            ],
            trackComponents: true,
            tracePropagationTargets: [config.public.sentryTraceTarget],
            tracesSampleRate: 0.07,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1,
            ignoreErrors: [
                'Unable to preload CSS',
                'Importing a module script failed',
                'Failed to fetch dynamically imported module',
                "undefined is not an object (evaluating 'a.L')", // Caused by google translate
            ],
        });
    }
});
