<script setup lang="ts">
/* eslint-disable vue/no-v-html */
export interface Props {
    iconHtml: string | { [key: string]: string };
}

const props = defineProps<Props>();
</script>

<template>
    <div class="base-icon" v-html="props.iconHtml"></div>
</template>

<style lang="postcss" scoped>
.base-icon {
    fill: var(--base-icon-fill, currentcolor);
    stroke: var(--base-icon-stroke, currentcolor);
    stroke-width: var(--base-icon-stroke-width, 1);
    width: var(--base-icon-size, 1rem);
    height: var(--base-icon-size, 1rem);
    display: flex;
    justify-content: center;
    align-items: center;

    &:deep(svg) {
        width: 100%;
        height: 100%;
    }
}
</style>
